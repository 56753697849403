<script setup lang="ts">
import type { Copy, Link } from "@/types/prismic.js";

const props = defineProps<{
  title: Copy[];
  subtitle?: Copy[];
  readMore?: Copy[];
  backgroundImage?: string;
  backgroundVideo?: string;
  absoluteTop: boolean;
  buttonLink?: Link;
  buttonText?: string;
  isLegislative: boolean;
}>();

const topOffset = ref(0);

const showReadMore = ref(false);

const pageHeader = ref<HTMLElement>();

const bumpUp = () => {
  if (props.absoluteTop && pageHeader.value) {
    pageHeader.value.style.marginTop = String(topOffset.value * -1) + "px";

    pageHeader.value.style.paddingTop = String(topOffset.value) + "px";
  }
};

onMounted(() => {
  if (pageHeader.value) {
    topOffset.value = pageHeader.value.getBoundingClientRect().y;
  }

  window.addEventListener("resize", bumpUp);

  bumpUp();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", bumpUp);
});
</script>

<template>
  <div
    ref="pageHeader"
    class="page-header"
    :class="{ 'has-image': !!backgroundImage, isLegislative }"
    :style="{
      backgroundImage:
        backgroundImage && !backgroundVideo ? `url(${backgroundImage})` : '',
    }"
  >
    <div class="video-wrapper">
      <ClientOnly>
        <video
          v-if="backgroundVideo"
          :loop="true"
          :muted="true"
          :autoplay="true"
        >
          <source
            type="video/mp4"
            :src="backgroundVideo"
          />
        </video>
      </ClientOnly>
    </div>
    <MarginContainer>
      <div>
        <div>
          <PrismicText
            v-if="title"
            :blocks="title"
          />
          <PrismicText
            v-if="subtitle"
            :blocks="subtitle"
          />
          <p>
            <a
              v-if="readMore?.[0]?.text && !showReadMore"
              class="read-more"
              @click="showReadMore = true"
            >Read more <Icon name="icons:dropdown-arrow" /></a>
          </p>
          <PrismicText
            v-if="readMore && showReadMore"
            :blocks="readMore"
          />
        </div>
        <PrismicLink
          v-if="buttonLink"
          :link="buttonLink"
        >
          <AppButton>
            {{ buttonText }} <Icon
              v-if="isLegislative"
              name="icons:download"
            />
          </AppButton>
        </PrismicLink>
      </div>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.page-header {
  margin-bottom: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;

  @include mobile {
    margin-bottom: 0;
  }

  &.isLegislative {
    margin-bottom: 0;

    &:deep(h1) {
      font-size: 2.75rem;
    }

    section {
      padding-bottom: 0;

      & > div > .prismic-link {
        margin-top: 24px;

        svg {
          float: right;
          margin-left: 6px;
        }
      }
    }
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.has-image {
    padding-bottom: 320px;

    @include tablet {
      padding-bottom: 80px;
    }

    section {
      background-color: transparent;

      > div {
        border-bottom: 0 none;
      }
    }
  }

  section {
    padding-top: 0;

    > div {
      border-bottom: 1px solid $blue3;
      display: flex;
      justify-content: space-between;

      @include tablet {
        flex-direction: column;
      }

      > div:first-child {
        max-width: 60%;

        @include mobile {
          max-width: initial;
        }

        > p > a.read-more {
          cursor: pointer;
          font-size: rem-calc(20px);

          .iconify {
            vertical-align: middle;
            color: $blue2;
          }
        }
      }

      :deep(> div:first-child) {
        font-size: rem-calc(20px);

        @include tablet {
          font-size: rem-calc(18px);
        }
      }

      > .prismic-link {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
