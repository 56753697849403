<script setup lang="ts">
defineProps<{
  flag: string;
}>();

const FLAGS = {
  "": "na",
  "N/A": "na",
  "Belgium": "belgium",
  "Canada": "canada",
  "Council of Europe": "council-of-europe",
  "Cyprus": "cyprus",
  "Czech Republic": "czech",
  "Denmark": "denmark",
  "EU": "eu",
  "France": "france",
  "Germany": "germany",
  "Greece": "greece",
  "Hungary": "hungary",
  "India": "india",
  "Indonesia": "indonesia",
  "Ireland": "ireland",
  "Italy": "italy",
  "Japan": "japan",
  "Latvia": "latvia",
  "Lithuania": "lithuania",
  "Luxembourg": "luxembourg",
  "Malta": "malta",
  "Netherlands": "netherlands",
  "The Netherlands": "netherlands",
  "New Zealand": "new-zealand",
  "OIE": "oie",
  "Poland": "poland",
  "Portugal": "portugal",
  "Slovakia": "slovakia",
  "Slovenia": "slovenia",
  "South Africa": "south-africa",
  "Spain": "spain",
  "Sweden": "sweden",
  "Switzerland": "switzerland",
  "USA": "usa",
  "U.S.A.": "usa",
};

const isFlagName = (flagName: string): flagName is keyof typeof FLAGS => flagName in FLAGS;
</script>

<template>
  <Icon
    v-if="isFlagName(flag)"
    :name="`flags:${FLAGS[flag]}`"
  />
</template>
