<script setup lang="ts">
import { PrismicLink } from "#components";
import type { Link, Card, Copy } from "@/types/prismic.js";

defineProps<{
  copy: Copy[];
  sideCopy: Copy[];
  cards: Card[];
  buttonLink: Link;
  buttonText: string;
}>();

const cardsContainer = ref<null | HTMLElement>(null);

const visibleCards = ref(1);

const updateVisibleCards = (isDesktop: boolean) =>
  (visibleCards.value = isDesktop ? 3 : 1);

onMounted(() => {
  const matchMedia = window.matchMedia("(max-width: 1023px)");

  updateVisibleCards(!matchMedia.matches); // Execute on page load

  matchMedia.addEventListener("change", (e) => updateVisibleCards(!e.matches)); // Execute on viewport change
});

const scrollPosition = ref(0);

const canScrollLeft = computed(
  () => import.meta.client && !!cardsContainer.value?.children[scrollPosition.value - 1],
);

const canScrollRight = computed(
  () =>
    import.meta.client && !!cardsContainer.value?.children[scrollPosition.value + visibleCards.value],
);

const scrollLeft = () => {
  if (cardsContainer.value && canScrollLeft.value) {
    cardsContainer.value.children[--scrollPosition.value]?.classList.remove("scrolled");
  }
};

const scrollRight = () => {
  if (cardsContainer.value && canScrollRight.value) {
    cardsContainer.value.children[scrollPosition.value++]?.classList.add("scrolled");
  }
};
</script>

<template>
  <div class="carousel-push">
    <MarginContainer>
      <div>
        <div>
          <PrismicText
            v-if="copy"
            :blocks="copy"
            class="copy"
          />
          <PrismicLink
            v-if="buttonLink"
            :link="buttonLink"
            class="tablet-up"
          >
            <AppButton>{{ buttonText }}</AppButton>
          </PrismicLink>
        </div>
        <div>
          <div>
            <PrismicText
              v-if="sideCopy"
              :blocks="sideCopy"
            />
            <div
              v-if="cards && cards.length > visibleCards"
              class="tablet-up"
            >
              <AppButton
                :disabled="!canScrollLeft"
                @click="scrollLeft"
              >
                <Icon
                  name="icons:arrow-left"
                  :size="24"
                />
              </AppButton>
              <AppButton
                :disabled="!canScrollRight"
                @click="scrollRight"
              >
                <Icon
                  name="icons:arrow-right"
                  :size="24"
                />
              </AppButton>
            </div>
          </div>
          <div
            v-if="cards"
            ref="cardsContainer"
            v-touch:swipe.left="scrollRight"
            v-touch:swipe.right="scrollLeft"
            class="cards"
          >
            <component
              :is="card.link ? PrismicLink : 'div'"
              v-for="(card, index) in cards"
              :key="index"
              :link="card.link"
            >
              <div
                v-if="card.image || !card.link"
                class="image"
                :style="{
                  backgroundImage: card.image ? `url(${card.image.url})` : '',
                }"
              />
              <div>
                <h3 v-if="card.title">
                  {{ card.title }}
                </h3>
                <p v-if="card.subtitle">
                  {{ card.subtitle }}
                </p>
              </div>
            </component>
          </div>
          <PrismicLink
            v-if="buttonLink"
            :link="buttonLink"
            class="mobile-only"
          >
            <AppButton>{{ buttonText }}</AppButton>
          </PrismicLink>
        </div>
      </div>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.carousel-push {
  font-size: rem-calc(20px);
  overflow-x: hidden;

  section {
    > div {
      border-top: 1px solid $blue3;
      padding-top: 16px;
      display: flex;
      flex-direction: column;

      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;

        .prismic-link {
          margin-top: 24px;
        }
      }

      > div:nth-child(2) {
        display: flex;

        @include mobile {
          flex-direction: column;
        }

        > div:nth-child(1) {
          width: 25%;
          z-index: 1;
          background-color: $white1;
          padding-right: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          margin-top: 24px;

          @include tablet {
            width: 50%;
          }

          @include mobile {
            width: auto;
          }

          &::before {
            // Cards are hidden behind this when scrolled
            content: "";
            position: absolute;
            right: 100%;
            height: 100%;
            width: 100%;
            background-color: $white1;
          }

          > :nth-child(1) {
            :first-child {
              margin-top: 0;
            }
          }

          :deep(button) {
            padding: 18px;
            margin-right: 24px;

            svg {
              display: block;
            }
          }
        }

        .cards {
          display: flex;
          justify-content: flex-start;
          gap: 24px;
          width: 75%;
          margin-top: 24px;

          @include tablet {
            width: 50%;
          }

          @include mobile {
            width: auto;
            gap: 16px;
          }

          > div,
          > .prismic-link {
            box-sizing: border-box;
            min-width: calc((100% / 3) - 16px);
            width: calc((100% / 3) - 16px);
            background-color: $blue4;
            border-radius: 2px;
            border: 1px solid $blue2;
            overflow: hidden;
            color: $blue1;
            text-decoration: none;
            transition: margin-left 300ms;

            @include tablet {
              min-width: 75%;
              width: 75%;
            }

            @include mobile {
              font-size: rem-calc(16px);
            }

            &.scrolled {
              margin-left: calc((100% / -3) - 8px);

              @include tablet {
                margin-left: calc(-75% - 24px);
              }

              @include mobile {
                margin-left: calc(-75% - 16px);
              }
            }

            .image {
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 2px;
              aspect-ratio: 4/3;
            }

            > div:nth-child(2) {
              padding: 0 24px 16px;

              @include mobile {
                padding: 16px;
              }
            }
          }
        }

        > .prismic-link {
          margin-top: 24px;
        }
      }
    }
  }
}
</style>
