import type { Database } from "@/types/database.js";

export default (policy: Database["data"][0]) => {
  const truthyTopics = getTruthyTopics(policy);

  const truthySpecies = getTruthySpecies(policy);

  const data = (useDatabase().value?.data ?? []).filter(
    (row) => row["Law / Policy"] !== policy["Law / Policy"],
  );

  return data
    .sort((a, b) => {
      return (
        Number(truthySpecies.filter((f) => getTruthySpecies(b).includes(f)).length)
        + Number(truthyTopics.filter((f) => getTruthyTopics(b).includes(f)).length)
        - (Number(truthySpecies.filter((f) => getTruthySpecies(a).includes(f)).length)
        + Number(truthyTopics.filter((f) => getTruthyTopics(a).includes(f)).length))
      );
    })
    .slice(0, 4);
};
