export default (
  headerCells: string[],
) => {
  const items = [
    ...new Set<string>(useDatabase().value?.data.map((row) => row[headerCells[4] ?? ""] ?? "")),
  ].filter(Boolean);

  items.sort();

  return items;
};
