<script setup lang="ts">
import type { ContentNavLevelLink } from "@/types/prismic.js";

const props = defineProps<{
  links: ContentNavLevelLink[];
  isArticle: boolean;
}>();

const stuck = ref(false);
const navOuter = ref<null | HTMLElement>(null);
const nav = ref<null | HTMLElement>(null);

watch(useContentCopyMargin(), (value) => {
  if (nav.value) {
    const margin = props.isArticle ? 127 : 0;
    nav.value.style.maxWidth = String(value - 80 + margin) + "px";
  }
});

const stickyScroll = () => {
  if (navOuter.value) {
    stuck.value = navOuter.value.getBoundingClientRect().y <= 5;
  }
};

const addOpen = (index: number) => {
  if (openIndexes.value.includes(index)) {
    openIndexes.value.splice(openIndexes.value.indexOf(index), 1);
  } else {
    openIndexes.value.push(index);
  }
};

const openIndexes = ref<number[]>([]);

const linksWithLevels = computed(() => {
  const links = [];
  let level0LinkIndex = -1;
  let level1LinkIndex = 0;
  for (const link of props.links) {
    if (!link.navigation_level || link.navigation_level === "0") {
      const newLink = {
        ...link,
        links: [] as ContentNavLevelLink[],
        open: false,
      };
      links.push(newLink);
      level0LinkIndex += 1;
    }
    if (link.navigation_level === "1") {
      const newLink = {
        ...link,
        links: [] as ContentNavLevelLink[],
        open: false,
      };

      links[level0LinkIndex]?.links.push(newLink);
      level1LinkIndex = (links[level0LinkIndex]?.links.length ?? 0) - 1;
    }
    if (link.navigation_level === "2") {
      links[level0LinkIndex]?.links[level1LinkIndex]?.links?.push(link);
    }
  }
  return links;
});

onMounted(() => {
  window.addEventListener("scroll", stickyScroll);

  stickyScroll();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", stickyScroll);
});
</script>

<template>
  <div
    v-if="links.length"
    ref="navOuter"
    class="content-navigation"
  >
    <MarginContainer>
      <div
        ref="nav"
        class="side-nav"
        :class="{ stuck }"
      >
        <div
          v-for="(link, index) in linksWithLevels"
          :key="index"
          class="side-nav-item"
          :class="{ nolinks: link.links.length == 0 }"
        >
          <a :href="`#${link.title}`">{{ link.title }}</a>
          <AppDropdownButton
            v-if="link.links.length"
            :open="openIndexes.includes(index)"
            @click="addOpen(index)"
          />

          <div
            v-if="link.links.length"
            v-show="openIndexes.includes(index)"
          >
            <a
              v-for="(link1, index1) in link.links"
              :key="index1"
              :href="`#${link1.title}`"
              class="nav1"
            >
              {{ link1.title }}

              <div
                v-if="link1.links?.length"
                class="side-level2"
              >
                <a
                  v-for="(link2, index2) in link1.links"
                  :key="index2"
                  :href="`#${link2.title}`"
                  class="nav2"
                >
                  {{ link2.title }}
                </a>
              </div>
            </a>
          </div>
        </div>
      </div>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.content-navigation {
  height: 0;

  @include tablet {
    display: none;
  }

  section {
    z-index: 0;

    .side-nav {
      padding: 12px 24px;
      background-color: $grey2;
      box-sizing: border-box;
      float: left;
      max-height: calc(100vh - 290px);
      overflow-y: scroll;
      max-width: 210px;

      &.stuck {
        position: fixed;
        top: 24px;
      }

      .side-nav-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 12px 0;
        border-bottom: 1px solid $blue3;

        &:last-child {
          border-bottom: 0 none;
        }

        svg {
          float: right;
          min-width: 24px;
          margin-left: 12px;
        }

        & > a {
          width: calc(100% - 38px);
        }

        &.nolinks > a {
          width: 100%;
        }
      }

      a {
        color: $blue1;
        font-family: $font-secondary;
        font-size: rem-calc(18px);
        font-weight: 700;
        text-decoration: none;
        word-break: break-word;
        hyphens: auto;
        display: block;
        width: 100%;
        user-select: none;

        &.nav1,
        &.nav2 {
          font-weight: normal;
          padding: 12px 12px 0;
          background-color: #eee;
          margin-left: -12px;
          margin-right: -12px;
        }

        &.nav1:first-of-type {
          margin-top: 12px;
        }

        &.nav2 {
          padding-left: 32px;

          &:last-of-type {
            padding-bottom: 12px;
          }
        }
      }

      .side-level2 {
        position: relative;
        padding: 8px 0;

        &::before {
          content: "";
          display: block;
          width: 2px;
          height: calc(100% - 24px);
          position: absolute;
          left: 0;
          top: 12px;
          background-color: #dadada;
        }
      }
    }
  }
}
</style>
