export default () => computed(() => {
  const headers = useHeaders().value;

  return {
    Topic: "The topic of the legislation or policy covered by the text",
    Species: "The animal, or type of food production, covered by the text",
    [headers[0] ?? ""]: "Name of the text",
    [headers[1] ?? ""]: "Country or geographical area where the text applies",
    [headers[2] ?? ""]: "Country or state where the text applies",
    [headers[3] ?? ""]:
      "Whether the act is a law, regulation, or policy, or another type of text",
    [headers[4] ?? ""]: "Indicates whether the act is in force or not",
    [headers[5] ?? ""]: "Whether the text is binding or not",
    [headers[9] ?? ""]: "Date the text was adopted",
    [headers[10] ?? ""]:
      "Date when the entry was last updated by the CALF team",
  };
});
