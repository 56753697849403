<script setup lang="ts">
import type { Database } from "~/types/database.js";

defineProps<{
  infoKey: string;
  database: Database;
}>();

const infoLabels = useInfoLabels();
</script>

<template>
  <span v-if="infoLabels[infoKey]">
    <Icon
      name="icons:info"
      :size="24"
    />
    <span class="tooltip">{{ infoLabels[infoKey] }}</span>
  </span>
</template>

<style lang="scss" scoped>
span {
  position: relative;
  display: block;
  color: $blue5;

  .tooltip {
    display: none;
    position: absolute;
    top: 24px;
    right: 0;
    background-color: $white1;
    color: $blue1;
    border-radius: 2px;
    border: 1px solid $blue2;
    padding: 2px 4px;
    font-family: $font-body;
    font-weight: 500;
    font-size: rem-calc(16px);
    z-index: 1;
    white-space: initial;
    min-width: 120px;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}
</style>
