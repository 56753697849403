<script setup lang="ts">
const showModal = ref(false);

watch(showModal, (value) => {
  if (value) {
    document.body.classList.add("z-index-reset");
  } else {
    document.body.classList.remove("z-index-reset");
  }
});
</script>

<template>
  <div
    class="modal-wrapper"
    :class="{ closed: !showModal }"
    @click="showModal = true"
  >
    <Transition
      name="fade"
      mode="out-in"
      appear
    >
      <div
        v-if="showModal"
        class="modal"
        @click.stop="showModal = false"
      >
        <div @click.stop>
          <div
            class="close"
            @click.stop="showModal = false"
          />
          <slot name="modal" />
        </div>
      </div>
    </Transition>
    <slot name="link" />
  </div>
</template>

<style lang="scss" scoped>
.modal-wrapper {
  &.closed {
    cursor: pointer;
  }

  .modal {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($blue1, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      position: absolute;
      align-items: flex-start;
    }

    > div {
      position: relative;
      background-color: $white1;
      width: 50%;
      max-width: 680px;
      padding: 60px;
      border-radius: 8px;
      box-shadow: 0 -10px 40px 0 rgb(0 0 0 / 10%);

      @include tablet {
        padding: 24px;
        width: auto;
      }

      .close {
        position: absolute;
        top: 40px;
        right: 40px;
        border: 1px solid $blue2;
        border-radius: 4px;
        background-color: $blue4;

        &::after {
          content: "\00d7";
          width: 32px;
          height: 32px;
          cursor: pointer;
          font-family: sans-serif;
          font-size: rem-calc(30px);
          font-weight: normal;
          line-height: 0.7;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $blue2;
        }
      }
    }
  }
}
</style>
