export default () => computed(() => {
  const database = useDatabase();

  const items = [
    ...new Set<string>(
      database.value?.data.map((row) => row[useHeaders().value[1] ?? ""] ?? ""),
    ),
  ].filter(Boolean);

  items.sort();

  return items;
});
