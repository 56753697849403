<script setup lang="ts">
import type { Database } from "@/types/database.js";

const margin = ref(0);

onMounted(() => {
  margin.value = useContentCopyMargin().value;
});

defineProps<{
  header1: string;
  header2: string;
  header3: string;
  header4: string;
  rows: Database["data"];
}>();
</script>

<template>
  <div
    class="table-4-column"
    :style="{ marginLeft: margin - 35 + 'px' }"
  >
    <MarginContainer class="narrow">
      <table>
        <thead>
          <td v-if="header1">
            {{ header1 }}
          </td>
          <td v-if="header2">
            {{ header2 }}
          </td>
          <td v-if="header3">
            {{ header3 }}
          </td>
          <td v-if="header4">
            {{ header4 }}
          </td>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            :key="row.cell4_flag"
          >
            <td v-if="header1">
              {{ row.cell1 }}
            </td>
            <td v-if="header2">
              {{ row.cell2 }}
            </td>
            <td v-if="header3">
              {{ row.cell3 }}
            </td>
            <td v-if="header4">
              <FlagIcon
                v-if="row.cell4_flag"
                :flag="row.cell4_flag"
              />
              {{ row.cell4_flag }}
            </td>
          </tr>
        </tbody>
      </table>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.table-4-column {
  table {
    width: 100%;
    border-collapse: collapse;

    thead td {
      background-color: #f5f5f5;
      font-weight: bold;
    }

    td {
      width: 10%;
      padding: 8px 16px;
      border: solid 1px #eee;
      vertical-align: middle;

      svg {
        margin-top: -1px;
        width: 32px;
        height: 24px;
        margin-right: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
